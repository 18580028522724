@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
    @apply text-white bg-emerald-300;
}

a,
abbr[tabindex] {
    @apply focus:outline-none focus:ring-2 focus:ring-emerald-300 focus:ring-offset-2 rounded-md;
}

abbr[aria-label] {
    @apply underline decoration-dotted;
}

.tooltip {
    @apply hidden absolute left-0 top-0;
    @apply bg-gray-700 text-white rounded-md py-1 px-2 text-xs;
    @apply pointer-events-none;
}

.themed-underline {
    @apply bg-no-repeat bg-gradient-to-r from-emerald-100 to-sky-200;
    background-size: 100% 0.3em;
    background-position: 0 88%;
}

.bg-gradient-cta {
    @apply relative z-10;
    @apply bg-gradient-to-r from-emerald-300 to-sky-400;

    @apply before:absolute before:content-[''] before:inset-0 before:rounded-full before:-z-10;
    @apply before:bg-gradient-to-r before:from-sky-400 before:to-emerald-300;
    @apply before:transition-opacity before:opacity-0 before:duration-1000;
    @apply hover:before:opacity-100 focus:before:opacity-100;
}